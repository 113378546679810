import product from '@/components/product/index.vue'
import {mapActions, mapGetters} from "vuex";
export default {
  name: "success-payment",
  components:{
    product
  },
  computed:{
    ...mapGetters({
      popularProducts:`card/popularProducts`
    })
  },
  created() {
    this.fetchPopularProducts()
  },
  methods:{
    ...mapActions({
      fetchPopularProducts: `card/GET_POPULAR_PRODUCTS`,
    })
  }
}